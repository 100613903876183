import {getServerUrl, oAuthRequest} from "../utils/request";
import {config} from "../utils/constants";

export const getUserEnrollments = () => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.userUtilsEndpoint}enrollments`,
        method: 'get',
    });

}

export const getUserEnrollmentById = (enrollmentId) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.userUtilsEndpoint}enrollment/${enrollmentId}`,
        method: 'get',
    });

}

export const getUserEnrollmentByAssetId = (assetId) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.userUtilsEndpoint}enrollment/by-asset-id/${assetId}`,
        method: 'get',
    });

}

