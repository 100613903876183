import {forwardRef, useCallback, useEffect, useState} from "react";
import {getCurrentLanguageKey, getDPTAssetId, showError, showSuccess} from "../utils/util";
import {useTranslation} from "react-i18next";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {loadExerciseQuestions, postExerciseResult} from "../services/exercise";
import {Text} from '@clayui/core';
import {ClayRadio, ClayRadioGroup, ClayToggle} from '@clayui/form';
import ClayTabs from '@clayui/tabs';
import ClayButton from '@clayui/button';
import ClayForm, {ClayInput} from '@clayui/form';
import ClayProgressBar from "@clayui/progress-bar";
import QuizResultSheet from "./QuizResultSheet";

const ExerciseView = forwardRef((props, ref) => {

    const {exerciseId, height,educationPageUrl,badgePageUrl} = props;


    const {t, i18n: {changeLanguage, language}} = useTranslation();

    const [active, setActive] = useState(0);
    const [answers, setAnswers] = useState({});
    const [assetId, setAssetId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [scoreSheetOverall, setScoreSheetOverall] = useState(null);

    const handleQuizReset = () => {

        setActive(0);
        setProgress(0);
        setScoreSheetOverall(null);

    }

    const prepareAnswersObject = (questions) => {

        let answersObject = {};

        questions.forEach(question => {

            switch (question.type) {

                case "selectSingleChoice": {

                    answersObject[question.id] = {};

                    answersObject[question.id] = null;

                    break;
                }
                case "selectMultipleChoice": {

                    answersObject[question.id] = {};

                    question.answers.forEach(answer => {

                        answersObject[question.id][answer.id] = false;
                    })

                    break;
                }
                case "freeText": {

                    answersObject[question.id] = "";

                    break;
                }
            }


        })

        setAnswers(answersObject);

    }

    const validateAnswers = useCallback(() => {

        let valid = true;

        Object.keys(answers).map((key, index) => {
            if (!answers[key]) {

                showError(t('missing-answer'), t('missing-answer-error', {questionIndex: index + 1}));

                valid = false;
            }
        })

        return valid;

    }, [answers])

    const handleSubmit = useCallback(async () => {

        if (validateAnswers()) {

            setIsLoading(true);

            setScoreSheetOverall(await postExerciseResult(assetId, answers));

            setIsLoading(false);
        }

    }, [answers])

    const handleNextQuestion = useCallback(() => {


        if ((active + 1) < questions.length) {

            setActive((active + 1));
        }

    }, [active, questions])

    const handlePreviousQuestion = useCallback(() => {

        if ((active - 1) >= 0) {

            setActive((active - 1));
        }

    }, [active, questions])


    useEffect(() => {
        if (assetId && assetId.toString().length > 0)
            loadExerciseQuestions(assetId).then(result => {

                setQuestions(result);

                prepareAnswersObject(result);

            });

    }, [assetId])

    useEffect(() => {

        let currentProgress = 0

        if (active > 0) {

            currentProgress = (active / questions.length) * 100;

        }

        setProgress(currentProgress);

    }, [active, questions])

    useEffect(() => {

        changeLanguage(getCurrentLanguageKey()).then(() => {

        });

        if (exerciseId && exerciseId.toString().length > 0) {

            setAssetId(exerciseId);

        } else {

            setAssetId(getDPTAssetId());

        }

    }, [changeLanguage]);

    return (
        <>
            {isLoading && (
                <ClayLoadingIndicator displayType="secondary" size="sm"/>
            )}

            {scoreSheetOverall && (
                <>
                    <ClayProgressBar value={100}/>

                    <QuizResultSheet handleReset={handleQuizReset} badgePageUrl={badgePageUrl} educationPageUrl={educationPageUrl} scoreSheetOverall={scoreSheetOverall}  title={"test"} ></QuizResultSheet>

                </>
            )}
            {!scoreSheetOverall && (
                <>
                    <ClayProgressBar value={progress}/>

                    <ClayTabs active={active}>
                        <ClayTabs.Content activeIndex={active} className={"w-100"} fade>
                            {questions && answers && (
                                questions.map((question, index) => (
                                    <ClayTabs.TabPane aria-labelledby="tab-1">
                                        <div className="question">
                                            <div className="question-header">
                                                <span className="component-title">{t('question')} {index + 1}</span>
                                            </div>
                                            <div style={{minHeight: height}} className="question-body">
                                                <div className="mb-4">
                                                    <Text>
                                                        {question.title}
                                                    </Text>
                                                </div>
                                                <div className="mb-3">

                                                    {question.answers && question.type === "selectSingleChoice" && (
                                                        <ClayRadioGroup key={question.id} value={answers[question.id]}
                                                                        onChange={(value) => {
                                                                            let newAnswers = {...answers};

                                                                            newAnswers[question.id] = value;

                                                                            setAnswers(newAnswers);
                                                                        }}>
                                                            {
                                                                question.answers.map((answer, index) => <ClayRadio
                                                                    key={answer.id} label={answer.title}
                                                                    value={answer.id}/>)
                                                            }
                                                        </ClayRadioGroup>

                                                    )}

                                                    {question.answers && question.type === "selectMultipleChoice" && question.answers.map((
                                                        answer, index) =>
                                                        <div className="mb-3">
                                                            <ClayToggle key={answer.id} label={answer.title} symbol={{
                                                                off: "times",
                                                                on: "check"
                                                            }} onToggle={(value) => {

                                                                let newAnswers = {...answers};

                                                                newAnswers[question.id][answer.id] = value;

                                                                setAnswers(newAnswers);

                                                            }} toggled={answers[question.id][answer.id]}/>
                                                        </div>
                                                    )}

                                                    {question.type === "freeText" && (
                                                        <div className="mb-3">
                                                            <ClayForm.Group>

                                                                <label
                                                                    htmlFor={`text_${question.id}`}>{t('your-answer')}</label>

                                                                <ClayInput
                                                                    key={question.id}
                                                                    id={`text_${question.id}`}
                                                                    component="textarea"
                                                                    placeholder={t('insert-your-answer-here')}
                                                                    type="text"
                                                                    value={answers[question.id]}
                                                                    onChange={(event) => {

                                                                        let newAnswers = {...answers};

                                                                        newAnswers[question.id] = event.target.value;

                                                                        setAnswers(newAnswers);

                                                                    }}
                                                                />
                                                            </ClayForm.Group>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="question-footer">

                                                <ClayButton disabled={isLoading} displayType={"secondary"}
                                                            onClick={handlePreviousQuestion}>{t('back')}</ClayButton>

                                                {active === questions.length - 1 && (

                                                    <ClayButton disabled={isLoading} displayType={"primary"} onClick={handleSubmit}>
                                                        {t('submit')}

                                                        {isLoading && (
                                                            <span className="inline-item inline-item-before mx-2">
                                                            <ClayLoadingIndicator displayType="secondary" size="sm"/>
                                                        </span>
                                                        )}

                                                    </ClayButton>

                                                )}

                                                {active < questions.length - 1 && (

                                                    <ClayButton displayType={"primary"}
                                                                onClick={handleNextQuestion}>{t('next')}</ClayButton>
                                                )}


                                            </div>
                                        </div>
                                    </ClayTabs.TabPane>
                                ))
                            )}
                        </ClayTabs.Content>
                    </ClayTabs>
                </>

            )}

        </>
    );

});

export default ExerciseView;
